<!--
  右侧导航栏
-->
<template>
  <div class="fixed-menu" :class="{ animationName }" @mouseenter="mouseenter" @mouseleave="animationName = ''">
    <ul class="menu">
      <li
        class="cp menu-item"
        :class="{ active: activeId == index + 1 }"
        v-for="(item, index) in rightMenu"
        :key="index"
        @click.stop="toTargetPos(item, index + 1)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'flxedMenu',

  props: {
    rightMenu: Array,
    activeId: Number
  },

  data() {
    return {
      animationName: ''
    }
  },

  mounted() {
    window.addEventListener('scroll', this.animation)
  },

  destroyed() {
    clearTimeout(this.times)
    this.animationName = ''
    window.removeEventListener('scroll', this.animation)
  },

  methods: {
    toTargetPos({ ref }, index) {
      this.$emit('toTargetPos', index)
      const obj = document.getElementById(ref)
      this.$nextTick(() => {
        window.scrollTo({
          top: ref != 'cSetting' ? obj.offsetTop - 60 : obj.offsetTop - 80,
          behavior: 'smooth'
        })
      })
    },

    mouseenter() {
      this.animationName = 'start'
      clearTimeout(this.times)
    },

    animation() {
      this.mouseenter()
      this.times = setTimeout(() => {
        this.animationName = ''
      }, 3000)
    }
  }
}
</script>
<style lang="scss" scoped>
.fixed-menu {
  position: fixed;
  top: 40%;
  right: 0;
  z-index: 70;
  transform: translateX(80%);
  transition: transform 0.35s;
  &.animationName {
    transform: translateX(0);
  }
  .menu {
    width: 78px;
    margin-right: 20px;
    padding: 10px 0 10px 10px;
    background: #fff;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    .menu-item {
      width: 100%;
      margin-bottom: 13px;
      font-size: 12px;
      color: #333333;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .active {
      color: #0aa29b;
    }
  }
}
</style>
